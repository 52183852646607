<template>
  <ValidationProvider
    :name="$t(sLabel)"
    :rules="required ? 'required' : undefined"
    slim
    vid="customer_id"
  >
    <template #default="{ errors, valid }">
      <v-autocomplete
        v-model="iCustomerId"
        :chips="multiple"
        :clearable="!disabled"
        :disabled="disabled"
        :error-messages="errors"
        :items="items"
        :label="hideLabel ? undefined : $t(sLabel)"
        :loading="bLoading"
        :menu-props="{ offsetY: true }"
        :multiple="multiple"
        :success="required ? valid : undefined"
        hide-details="auto"
        item-text="firm.name"
        item-value="id"
        no-filter
        v-bind="obAttrs"
        @change="onChange"
        @update:search-input="onSearch"
        @click:clear="onCleanSelection"
      >
        <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotProps">
          <slot :name="name" v-bind="slotProps" />
        </template>

        <template #item="{ item }">
          <slot :item="item" name="item">
            <customer-preview :customer="item" />
          </slot>
        </template>

        <template v-if="!multiple && (create || hasUpdate)" #append-outer>
          <slot name="update" />
          <customer-form-dialog
            v-if="create"
            :emit-only="emitOnly"
            :require-dgi="requireDgi"
            create
            @created="onCreatedCustomer"
          />
        </template>
      </v-autocomplete>
    </template>
  </ValidationProvider>
</template>

<script lang="ts">
import type { CustomerData, FirmData } from "@planetadeleste/vue-mc-gw";
import { Customer, CustomerCollection } from "@planetadeleste/vue-mc-gw";
import { Component, Prop, VModel, Vue, Watch } from "vue-property-decorator";
import type { DebounceFunction } from "@/plugins/helpers";

import CustomerPreview from "@/modules/customers/components/CustomerPreview.vue";
import CustomerFormDialog from "@/modules/customers/components/CustomerFormDialog.vue";
import { isNumeric } from "mathjs";
import { debounce, defaults, get, isEmpty, isNil } from "lodash";

@Component({
  components: { CustomerPreview, CustomerFormDialog },
})
export default class CustomerSelect extends Vue {
  @VModel({ type: [Number, Array] }) iCustomerId!: number | number[];
  @Prop(Boolean) readonly endCustomer!: boolean;
  @Prop(Boolean) readonly endCustomerOnInit!: boolean;
  @Prop(Boolean) readonly create!: boolean;
  @Prop(Boolean) readonly emitOnly!: boolean;
  @Prop(Boolean) readonly requireDgi!: boolean;
  @Prop(Boolean) readonly required!: boolean;
  @Prop(Boolean) readonly provider!: boolean;
  @Prop(Boolean) readonly customer!: boolean;
  @Prop(Boolean) readonly local!: boolean;
  @Prop(Boolean) readonly foreign!: boolean;
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop(Boolean) readonly multiple!: boolean;
  @Prop(Boolean) readonly hideLabel!: boolean;

  obCollection: CustomerCollection = new CustomerCollection();
  obSelectedCustomer: Customer | undefined = undefined;
  fnDebounceSearch!: DebounceFunction;
  sSearch = "";
  bLoading = false;
  bInit = false;

  get items() {
    return this.obCollection.getModelList();
  }

  get obCustomer(): Customer | undefined {
    if (this.multiple) {
      return undefined;
    }

    const obFilter: Record<string, any> = this.iCustomerId
      ? { id: this.iCustomerId }
      : { end_customer: true };

    return this.obCollection.length
      ? this.obCollection.find(obFilter)
      : undefined;
  }

  get sCustomerName() {
    const obFirm: FirmData | null = this.obCustomer
      ? this.obCustomer.get("firm")
      : null;

    return obFirm ? obFirm.name : null;
  }

  get sLabel() {
    return this.provider ? "provider" : "customer";
  }

  get hasUpdate() {
    return !!get(this.$slots, "update") && !this.disabled;
  }

  get obAttrs(): Record<string, any> {
    return defaults(this.$attrs, {
      dense: true,
      outlined: true,
    });
  }

  // @Watch("sSearch")
  onSearch(sValue: string) {
    this.sSearch = sValue;
    this.fnDebounceSearch();
  }

  @Watch("endCustomer")
  onChangeEndCustomer(bValue: boolean) {
    if (bValue) {
      this.search();
    }
  }

  @Watch("provider")
  onChangeProvider(bValue: boolean) {
    if (bValue) {
      if (this.obCustomer && !this.obCustomer.is_provider) {
        this.cleanSelection();
      }

      this.search();
    }
  }

  @Watch("customer")
  onChangeCustomer(bValue: boolean) {
    if (bValue) {
      if (this.obCustomer && !this.obCustomer.is_customer) {
        this.cleanSelection();
      }

      this.search();
    }
  }

  @Watch("local")
  onChangeLocal() {
    if (this.obCustomer) {
      this.cleanSelection();
    }

    this.search();
  }

  @Watch("foreign")
  onChangeForeign() {
    if (this.obCustomer) {
      this.cleanSelection();
    }

    this.search();
  }

  @Watch("value")
  onChangeValue() {
    this.loadSelected();
  }

  created() {
    this.fnDebounceSearch = debounce(this.search, 500);
  }

  mounted() {
    this.obCollection.on("fetch", async () => {
      await this.loadSelected();

      if (!this.obCustomer || !this.endCustomer || !this.sCustomerName) {
        return;
      }

      this.obCollection.bySearch(this.sCustomerName);
      this.sSearch = this.sCustomerName;

      this.$emit("input", this.obCustomer.id);
      this.$emit("change", this.obCustomer);
    });

    if (this.endCustomerOnInit && isEmpty(this.iCustomerId)) {
      this.bInit = true;
      this.initialLoad();
    }

    this.loadSelected();
  }

  async initialLoad() {
    if (this.multiple) {
      this.cleanSelection();
    }

    if (!this.endCustomerOnInit || !isEmpty(this.iCustomerId) || !this.bInit) {
      return;
    }

    this.bInit = false;
    this.obCollection.filterBy({ endCustomer: 1 });
    await this.search();
    const obCustomer = this.obCollection.first();
    if (!obCustomer) {
      this.obCollection.clearFilters();
      await this.search(true);
      return;
    }

    this.$emit("input", obCustomer.id);
    this.$emit("change", obCustomer);
  }

  async loadSelected() {
    if (
      !this.iCustomerId ||
      (this.multiple && isEmpty(this.iCustomerId)) ||
      !isNil(this.obCollection.find({ id: this.iCustomerId }))
    ) {
      return;
    }

    const obModel = new Customer({ id: this.iCustomerId as number });
    await obModel.fetch();
    this.onCreatedCustomer(obModel);
  }

  async search(skipFilters = false) {
    if (!skipFilters) {
      if (
        this.endCustomer &&
        !this.provider &&
        (isNil(this.sSearch) || isEmpty(this.sSearch))
      ) {
        this.obCollection.filterBy({ endCustomer: 1 });
      }

      // Provider or Customer (not both)
      if (this.provider) {
        this.obCollection.filterBy({ provider: 1 });
      } else if (this.customer) {
        this.obCollection.filterBy({ customer: 1 });
      }

      // Local or Foreign
      if (this.local) {
        this.obCollection.filterBy({ local: 1 });
      } else if (this.foreign) {
        this.obCollection.filterBy({ foreign: 1 });
      }

      if (!isEmpty(this.sSearch)) {
        const sFilterSearch = get(this.obCollection.get("filters"), "search");

        if (
          sFilterSearch == this.sSearch ||
          (this.sCustomerName && this.sCustomerName == this.sSearch)
        ) {
          return;
        }

        this.obCollection.bySearch(this.sSearch);
      }
    }

    this.obCollection.clear();
    this.obCollection.page(1);

    this.bLoading = true;
    await this.obCollection.fetch();
    this.obCollection.clearFilters();
    this.bLoading = false;
  }

  cleanSelection() {
    this.$emit("input", this.multiple ? [] : undefined);
  }

  onCleanSelection() {
    this.cleanSelection();
  }

  onChange(iValue: number | number[]) {
    const obCustomer = this.multiple
      ? this.obCollection.filter((obItem: Customer) => {
          return isNumeric(iValue)
            ? obItem.id === iValue
            : iValue.includes(obItem.id);
        })
      : this.obCollection.find({ id: iValue });
    this.$emit("change", obCustomer);
  }

  onCreatedCustomer(obData: Partial<CustomerData> | Customer) {
    if (!obData.id) {
      return;
    }

    if (!this.obCollection.find({ id: obData.id })) {
      const obCustomer =
        obData instanceof Customer ? obData : new Customer(obData);
      this.obCollection.add(obCustomer);
    }

    this.$emit("input", obData.id);
    this.onChange(obData.id);
  }
}
</script>

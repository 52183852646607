<template>
  <gw-dialog
    v-model="dialog"
    hide-actions
    @open:dialog="openDialog"
    @close:dialog="closeDialog"
  >
    <template #activator="{ on }">
      <v-btn color="primary" icon v-on="on">
        <icon-edit v-if="customer?.id || !create" />
        <icon-add v-else />
      </v-btn>
    </template>

    <template v-if="dialog">
      <v-form @submit.prevent>
        <customer-form
          v-model="customer"
          :firm-only="firmOnly"
          :loading="loading"
          :require-dgi="requireDgi"
          dirty
          v-bind="$attrs"
          @cancel="cancel"
          @save="save"
        />
      </v-form>
    </template>
  </gw-dialog>
</template>

<script lang="ts">
import type { CustomerData } from "@planetadeleste/vue-mc-gw";
import { Customer } from "@planetadeleste/vue-mc-gw";
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import { EventBus } from "@/services/event-bus";

import CustomerForm from "@/modules/customers/components/CustomerForm.vue";
import GwDialog from "@/components/common/GwDialog.vue";

const defaultCustomerData: Partial<CustomerData> = {
  active: true,
  type: 1,
};

@Component({
  components: { CustomerForm, GwDialog },
})
export default class CustomerFormDialog extends Vue {
  @VModel({ type: Object, default: () => new Customer(defaultCustomerData) })
  obModel!: Customer | Partial<CustomerData>;

  @Prop(Boolean) readonly create!: boolean;
  @Prop(Boolean) readonly firmOnly!: boolean;
  @Prop(Boolean) readonly requireDgi!: boolean;
  @Prop(Boolean) readonly emitOnly!: boolean;

  dialog = false;
  loading = false;

  get customer() {
    return this.obModel instanceof Customer
      ? this.obModel
      : new Customer(this.obModel);
  }

  created() {
    EventBus.on(
      "open.customer.form.dialog",
      (obConfig?: Record<string, boolean>) => {
        let bDialog = false;

        if (obConfig && obConfig.create && this.create) {
          bDialog = true;
        }

        if (obConfig && obConfig.firmOnly && this.firmOnly) {
          bDialog = true;
        }

        this.dialog = bDialog;
      }
    );
  }

  beforeDestroy() {
    EventBus.off("open.customer.form.dialog");
  }

  save() {
    if (this.create) {
      this.loading = true;
      this.customer.sync();
      const obData = this.customer.attributes;

      if (this.emitOnly) {
        this.$emit("created", obData);
        this.dialog = false;
        return;
      }

      const obCustomer = new Customer(obData);
      obCustomer.store().then((response) => {
        this.loading = false;
        if (!response || !response.getData()) {
          return;
        }

        this.$emit("created", response.getData().data);
        this.dialog = false;
      });
    } else {
      this.$emit("save", this.customer);
      this.dialog = false;
    }
  }

  cancel() {
    this.$emit("cancel");
    this.dialog = false;
  }

  openDialog() {
    this.$emit("open:dialog");
  }

  closeDialog() {
    this.$emit("close:dialog");
  }
}
</script>
